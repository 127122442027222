// Application
//
//

// First Name
// Last Name
// Country
// Reason for Applying
// Any previous background in Software Development
// Timezone
// Email
// Date of Birth
// Highest Level of Education
// Race
// Gender
// Do you require any accommodations in the classroom?

export default function Application() {
  return (
    <div>
      <h3>Student Application</h3>
      <form>
        <input placeholder="First Name" />
        <input placeholder="Last Name" />
      </form>
    </div>
  );
}