import useComponentVisible from '../../hooks/useComponentVisible';
import './styles.scss';

export default function Dialog(props) {
  const { ref, isComponentVisible } = useComponentVisible(true, props.onClose);

  return (
    <div ref={ref}>
      {isComponentVisible && (
        <div className="dialog" style={{top: `${window.scrollY + 350}px`}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="16" width="16" className="close" onClick={props.onClose}>
            <path d="M14.3,12.18a.24.24,0,0,1,0-.35l9.26-9.27a1.49,1.49,0,0,0,0-2.12,1.51,1.51,0,0,0-2.12,0L12.18,9.7a.25.25,0,0,1-.36,0L2.56.44A1.51,1.51,0,0,0,.44.44a1.49,1.49,0,0,0,0,2.12L9.7,11.83a.24.24,0,0,1,0,.35L.44,21.44a1.49,1.49,0,0,0,0,2.12,1.51,1.51,0,0,0,2.12,0l9.26-9.26a.25.25,0,0,1,.36,0l9.26,9.26a1.51,1.51,0,0,0,2.12,0,1.49,1.49,0,0,0,0-2.12Z" fill="#000000"></path>
          </svg>
          <p>Going into Hiding</p>
        </div>
      )}
    </div>
  );
}