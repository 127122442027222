import { HEADER_LINKS } from '../../constants/header';
import './styles.scss';

export default function Header(props) {
  return (
    <header className={`d-flex w100 header ${props.scrolled && 'white'}`}>
      <div className="w100">
        <div className="d-flex align-items-center padding-inline-start-4 padding-inline-end-4 content">
          <div className="d-flex mobile">
            <button className="d-inline-flex align-items-center justify-content-center padding-inline-start-4 padding-inline-end-4 button">
              <svg viewBox="0 0 24 24" height="32" focusable="false" aria-hidden="true"><path fill="currentColor" d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z"></path></svg>
            </button>
          </div>
          <div className="d-flex mobile-center">
            <div className="align-items-center logo">
              <img src={`/logo-no-background${props.scrolled ? '-reverse' : ''}.svg`} alt="logo" style={{ width: '100px' }}/>
            </div>
            <div className="navigation">
              <div className="items">
                {HEADER_LINKS.map(link => {
                  return (
                    <div className="nav-item">
                      <a href="#" onClick={props.moveToSection} className={`link ${props.scrolled && 'inverse'}`} data-section={link.href}>{link.label}</a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="d-flex actions">
            <a href="https://app.devgardener.com/login" className={`login ${props.scrolled && 'inverse'}`} style={{display: 'none'}}>Log In</a>
            <a href="https://forms.gle/9bcuVu56S8ijA9Kt9" target="_blank" className="apply">Apply Now</a>
          </div>
        </div>
      </div>
    </header>
  )
}
