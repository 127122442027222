import { useCallback, useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Header from './features/Header';
import Home from './pages/home';
import Application from './pages/application';
import './App.scss';

function App() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(document.documentElement.scrollTop > 0);
    };
    document.addEventListener('scroll', onScroll);

    return () => document.removeEventListener('scroll', onScroll);
  }, [scrolled]);

  const moveToSection = useCallback((e) => {
    e.preventDefault();
    const element = document.getElementById(e.target.getAttribute("data-section"));
    const yOffset = -30; 
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
    setScrolled(true);
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home moveToSection={moveToSection}/>
    },
    {
      path: '/application',
      element: <Application/>
    },
  ]);

  return (
    <>
      <Header moveToSection={moveToSection} scrolled={scrolled}/>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
